/* BOX */
.box {
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 20px;
    & > *:last-child {
        margin-bottom: 0;
    }
}

.box2 {
    display: block;
    background-color: white;
    border: 1px solid get-color('gray');
    border-radius: 4px;
    padding: 20px 20px 30px 20px;
    color: get-color('gray-8');
    font-size: 16px;
    line-height: 1.625;

    &:hover, &:focus {
        text-decoration: none;

        .box2__title {
            color: get-color('primary');
        }
    }

    > img {
        position: relative;
        width: calc(100% + 40px);
        top: -20px;
        left: -20px;
        max-width: none;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &__title {
        @extend .title-2;
        @extend .title-2--alt;
    }
}

.box--bordered {
    border: 3px solid get-color("white");
}


.box--enlarged {
    @include screen-size(large) {
        width: calc(100% + 30px);
        margin-left: -30px;
    }
}

@each $color, $hex in $colors {
    .box--#{$color} {
        background: $hex;
    }
}

.box--primary {
    background: get-color('primary');
}
